import React from "react";
import { Link } from "gatsby";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

import "./about.css";

const DigitalPage = () => {
  return (
    <Layout>
      <Head title="Digital" />
      <Link to="/digitalimages">
        <li>
          <h3>Glitch</h3>
        </li>
      </Link>
      <Link to="/syllables">
        <li>
          <h3>Syllables</h3>
        </li>
      </Link>
      <Link to="/blurboxes">
        <li>
          <h3>Blur Boxes</h3>
        </li>
      </Link>
      <Link to="/cleromancy">
        <li>
          <h3>Cleromancy</h3>
        </li>
      </Link>

      <Link to="/digitaltools">
        <li>
          <h3>Tools</h3>
        </li>
      </Link>
    </Layout>
  );
};

export default DigitalPage;
